import React, { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, OrbitControls } from '@react-three/drei'
import MacContainer from '../components/MacController'
import "../css/canvas.css"
const ThreeElement = () => {

  return (
    <div className='canvasdisplay'>
    <Canvas camera={{fov: 20, position: [40, -1, 120]}}  style={{ width: '100%', height: '100%', touchAction: 'none' }}>
        {/* <OrbitControls/> */}
        <Environment files={[
            'https://dl.polyhaven.org/file/ph-assets/HDRIs/exr/1k/studio_small_09_1k.exr'
        ]} />
        {/* <mesh>
            <boxGeometry/>
        </mesh> */}
        <MacContainer/>
    </Canvas>
    </div>
  )
}

export default ThreeElement