import React from 'react'

const Privacy = () => {

  return (
    <div>
        <h1>
            *Privacy Policy*
        </h1>

        <h4>
            *1. Introduction*  
        </h4>
        <p>
            This Privacy Policy explains how Lincfolio collects, uses, and protects your personal information when you access and use our platform.
        </p>

        <h4>
            *2. Information We Collect*
        </h4>
        <p>
            <ul>
                <li>
                     *Personal Information*: Includes data provided during registration, such as name, email address, and payment details.
                </li>
                <li>
                    *Usage Data*: We collect data on how you interact with our platform, including access times, device information, and log data.
                </li>
                <li>
                    *Cookies*: Lincfolio uses cookies to improve functionality and user experience.
                </li>
            </ul>
        </p>

        <h4>
            *3. How We Use Your Information*  
        </h4>
        <p>

            We use your information to:
            <ul>
                <li>
                     Provide and maintain the platform.
                </li>
                <li>
                     Improve user experience and platform performance.
                </li>
                <li>
                     Communicate with you regarding updates, promotions, and support.
                </li>
                <li>
                     Analyze and monitor platform trends.
                </li>
            </ul>
        </p>

        <h4>
            *4. Sharing Your Information*  
        </h4>
        <p>
            Lincfolio does not sell or rent your personal information. We may share data with trusted third-party service providers who assist in operating our platform and delivering services. We ensure these providers adhere to strict confidentiality obligations.
        </p>

        <h4>
            *5. Data Security*
        </h4>
        <p>
            We implement security measures to protect your personal information. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.
        </p>

        <h4>
            *6. Your Data Rights*
        </h4>
        <p>
            You have the right to:
            <ul>
                <li>
                    Access, update, or delete your personal information.
                </li>
                <li>
                    Opt-out of promotional communications.
                </li>
                <li>
                    Object to data processing or request restrictions in certain cases.
                </li>
            </ul>
        </p>
        
        <h4>
            *7. Changes to the Privacy Policy*      
        </h4>
        <p>
            Lincfolio may update this policy as needed. Users will be notified of significant changes, and continued use of the platform constitutes acceptance of the revised policy.
        </p>
  
        <h4>
            *8. Contact Us*      
        </h4>
        <p>
            For questions or concerns regarding this Privacy Policy, please contact us at business@lincfolio.com,
            support@lincfolio.com
        </p>
    </div>
  )
}

export default Privacy