import React from 'react'
import "../css/story.css";
import Person from '../images/person.jpg'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const LandingPage = () => {
    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);
    
    useGSAP(()=>{

        let animation1 = gsap.timeline();
        ScrollTrigger.create({
            animation: animation1,
            trigger: "#person",
            start: "top 80%",
            end: "top 40%",
            scrub: 2,
        });

        animation1.from("#person",{
            x: 200,
            duration: 1,
            borderRadius: "50%",
        });
        animation1.from("#storyheadline",{
            y: 20,
            opacity: 0,
            duration: 0.5
        });
        animation1.from("#storydata",{
            x: -100,
            opacity: 0,
            stagger: 0.3
        });
    },[]);
    return (
    <section id="story" className='mainheight'>
        <div className='container-fluid bgstory'>
            <div className='container'>
                <div className='row d-flex align-items-center mainheight'>
                    <div className='col-lg-6 '>
                        <img src={Person} className='img-fluid' id='person'/>
                    </div>
                    <div className='col-lg-6 text-white'>
                        <h1 className='cormorant-upright-bold' id='storyheadline'>COMPANY STORY</h1>
                        <p id='storydata'>Our founder, a seasoned trader himself, was frustrated 
                        with the lack of effective tools available to track his
                        progress and refine his strategy.</p> <br/>
                        <p id="storydata">
                        He spent countless hours manually logging his trades, only to find that he was
                        missing crucial insights and patterns.                            
                        </p><br/>

                        <p id="storydata">
                        Frustrated by the limitations of traditional spreadsheets
                        and paper journals, he set out to create a solution that
                        would revolutionize the way traders approach 
                        their craft.                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default LandingPage