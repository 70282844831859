import LandingPage from './pages/LandingPage';
import { Outlet } from 'react-router-dom';

function App() {
    return (
      <>
      <Outlet/>   
      </>
  );
}

export default App;
