import React from 'react'
import LandingPage from './LandingPage'
import StoryPage from "./StoryPage"
import BrandPage from './BrandPage'
import ThreeElement from './ThreeElement'
import Navbar from '../components/Navbar'
const Layout = () => {
  return (
    <>
        <Navbar/>
        <LandingPage/>
        <StoryPage/>
        <BrandPage/>
        <ThreeElement/>
    </>
  )
}

export default Layout