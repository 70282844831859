import React from 'react'
import Logo from "../images/logo.png";
import { AiOutlineMenu } from 'react-icons/ai';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const Navbar = () => {
    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    useGSAP(()=>{
        let navanim = gsap.timeline();
        ScrollTrigger.create({
            animation: navanim,
            trigger: "#story",
            start: "top 100%",
            end: "top 90%",
            scrub: true,
        });

        navanim.fromTo("#nav",            
            {backgroundColor: "#ffffff"},
            {backgroundColor: "#ffffffc9"}
        );
    },[])
  return (
    <header id="nav" className="fixed-top p-3 text-white">
        <div className="container">
            <div className="row align-items-center">
            <a href="/" className="col-6">
                <img src={Logo} style={{height: "50px"}}></img>
            </a>

            {/* <div className="col-6 d-flex justify-content-end">
                <AiOutlineMenu className='text-dark' style={{fontSize: "30px", cursor: "pointer"}}/>
            </div> */}
            </div>
        </div>
    </header>
  )
}

export default Navbar