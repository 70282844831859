import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react'
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineX, AiOutlineYoutube } from 'react-icons/ai';

const BrandPage = () => {
    gsap.registerPlugin(ScrollTrigger);
    
    useGSAP(()=>{
        var sn = gsap.timeline();
        var sm = gsap.timeline();
        var sl = gsap.timeline();
        var st = gsap.timeline();
        ScrollTrigger.create({
            animation: sn,
            trigger: "canvas",
            start: "top top",
            end: "top top",
            scrub: true,
        });
        
        sn.to("canvas",
            {position: "fixed"}
        );

        ScrollTrigger.create({
            animation: sm,
            trigger: ".closer",
            start: "bottom bottom",
            end: "bottom bottom",
            scrub: true,
        });

        sm.to("canvas",
            {position: "static", marginTop: "100vh"}
        );

        ScrollTrigger.create({
            animation: sl,
            trigger: "#brandposition",
            start: "top 50%",
            end: "top 40%",
            scrub: 2,
        });

        sl.from("#brandhead",{
            y: 20,
            opacity: 0,
            delay: 1,
            duration: 0.5
        });
        sl.from("#branddata",{
            x: -100,
            opacity: 0,
            stagger: 0.3
        });

        ScrollTrigger.create({
            animation: st,
            trigger: "#brandmarker",
            start: "bottom bottom",
            end: "top 50%",
            scrub: 2,
        });

        st.from("#brandshead",{
            y: 20,
            opacity: 0,
            duration: 0.5
        });
        st.from("#brandsdata",{
            x: -100,
            opacity: 0,
            stagger: 0.3
        });
    },[]);
  return (
    <div>
        <section id='brandposition'>
            <div className='container-fluid bgwhitem' style={{position: "absolute", zIndex: -1}}>
                <div className='container'>
                    <div className='row d-flex align-items-center mainheight'>
                        <div className='col-lg-6 '>
                            <h1 className='cormorant-upright-bold' id='brandhead'>BRAND POSITIONING</h1>
                                <p id='branddata' className=''>
                                As we are India’s #1st trading journal platform, our brand positioning is centred around 
                                the idea that trading success is not just about making money, but also about building a community of like-minded 
                                individuals who share knowledge, support each other, and learn from each other's experiences. 
                                </p>
                                <br/>
                                <p id='branddata' className=''>
                                This will help traders gain respect in our society and prosper in an endless ways possible.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='brandstory'>
            <div className='container-fluid bgstory' style={{position: "absolute", zIndex: -1 ,marginTop: "100vh"}}>
                <div className='container' id="brandmarker">
                    <div className='row d-flex align-items-center mainheight'>
                        <div className='col-lg-6 offset-lg-6 text-white'>
                            <h1 className='cormorant-upright-bold' id='brandshead'>BRAND STORY</h1>
                                <p id='brandsdata' className=''>
                                    At Lincfolio, we understand that trading is a high-stakes game that requires mental toughness, strategic thinking, and emotional discipline. Yet, many traders struggle to keep track of their progress, reflect on their mistakes, and adapt to changing market conditions.
                                </p>
                                <br/>
                                <p id='brandsdata' className=''>
                                    That's why we created Lincfolio - India’s first ever web application designed to help traders build a strong foundation for success.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='footer' >
        <div className="container-fluid home" style={{position: "absolute", zIndex: -1 ,marginTop: "200vh"}}>
            <div className='container'>
                <footer className="py-5" >
                    <div className="row closer">
                    <h5>Contact us</h5>
                    <p>We'd love to hear from you! Whether you have questions, feedback, or need support, feel free to reach out.</p>
                    <div className="col-lg-4" >
                        <h5>Phone no</h5>
                        <ul className="nav flex-column">
                        <li className="nav-item mb-2">
                            <small>
                                Customer Support:<br/> +91 8149711187
                            </small>
                        </li>
                        <li className="nav-item mb-2">
                            <small>
                                Available<br/>
                                ( Mon-Fri, 9 AM - 6 PM)
                            </small>
                        </li>
                        </ul>
                    </div>

                    <div className="col-lg-4">
                        <h5>Email</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"> 
                                <small>
                                    For general inquiries:  <a href="#" className="nav-link p-0 ">support@lincfolio.com  </a>
                                </small>
                            </li>
                            <li className="nav-item mb-2"> 
                                <small>
                                    For partnerships and business opportunities:<a href="#" className="nav-link p-0 ">business@lincfolio.com</a>
                                </small>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-2">
                        <h5>Address</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Lincfolio, 431001, Maharashtra, India </a></li>
                        </ul>
                    </div>
                    <div className="col-2">
                        <h5>Section</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#home" className="nav-link p-0 text-muted">Home</a></li>
                            <li className="nav-item mb-2"><a href="#story" className="nav-link p-0 text-muted">Company Story</a></li>
                            <li className="nav-item mb-2"><a href="#brandstory" className="nav-link p-0 text-muted">Brand Story</a></li>
                            <li className="nav-item mb-2"><a href="/termsandconditions" className="nav-link p-0 text-muted">Terms &amp; Conditions</a></li>
                            <li className="nav-item mb-2"><a href="/privacypolicy" className="nav-link p-0 text-muted">Privacy Policy</a></li>
                            <li className="nav-item mb-2"><a href="/cancellationandrefunds" className="nav-link p-0 text-muted">Cancellation &amp; Refund</a></li>
                        </ul>
                    </div>
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 border-top">
                    <p>© Copyright 2024 - Earn More India. All Rights Reserved.</p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3"><a className="link-dark" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thelincfolio?igsh=MWo2MXJmdm1pZTg4NQ=="><AiOutlineInstagram/></a></li>
                        <li className="ms-3"><a className="link-dark" target="_blank" rel="noopener noreferrer" href="https://x.com/lincfolio?s=21"><AiOutlineX/></a></li>
                        <li className="ms-3"><a className="link-dark" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61563870974614&mibextid=LQQJ4d"><AiOutlineFacebook/></a></li>
                        <li className="ms-3"><a className="link-dark" target="_blank" rel="noopener noreferrer"  href="https://youtube.com/@lincfolio?si=ce3KQldt9_eenMoo"><AiOutlineYoutube/></a></li>
                    </ul>
                    </div>
                </footer>
            </div>
        </div>
        </section>
    </div>
  )
}

export default BrandPage