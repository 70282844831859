import React from 'react'
import "../css/landing.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import Laptop from '../images/laptop.png'
import Cloudone from "../images/realistic-white-cloud-png.png"
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const LandingPage = () => {
    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);
    useGSAP(() => {
        var tlcloude = gsap.timeline()
        tlcloude.from("#headline",{
            y: 20,
            opacity: 0
        });
        tlcloude.from("#tagline",{
            y: 20,
            opacity: 0,
        });
        tlcloude.from("#span span",{
            y: 20,
            opacity: 0,
            stagger: 0.3,
        });
        tlcloude.from("#landingpara", {
            y: 20,
            opacity: 0,
            stagger: 0.5  
        });
        tlcloude.from("#btn", {
            y: 20,
            opacity: 0,
            stagger: 0.5  
        });
        tlcloude.from("#laptop",{
            y: 100,
            duration: 0.5,
            opacity: 0
        });
        tlcloude.from("#cloudone",{
            x: 100,
            duration: 0.5,
            opacity: 0
        }, "-=1");
        tlcloude.from("#cloudtwo",{
            x: -100,
            duration: 0.5,
            opacity: 0
        });
        tlcloude.to("#cloudone",{
            x: 200,
            duration: 0.5,
            opacity: 0
        });
        tlcloude.to("#cloudtwo",{
            x: -300,
            duration: 0.5,
            opacity: 0
        });
    
    },[]);
    return (

    <section id="home" className='mainheight'>
        <div className='container-fluid home'>
            <div className='container'>
                <div className='row d-flex align-items-center mainheight'>
                    <div className='col-lg-6 '>
                        <h1 className='cormorant-upright-bold' id='headline'>LINCFOLIO</h1>
                        <h2 className='fw-light fs-5' id='tagline'>"TRADING JOURNEY SIMPLFIED"</h2>
                        <p id="span">
                            <span className='p-1'>TRACK</span>
                            <span className='p-1'> <AiOutlineArrowRight /></span>
                            <span className='p-1'>ANALYZE </span>
                            <span className='p-1'> <AiOutlineArrowRight /></span>
                            <span className='p-1'>SUCCEED </span> 
                        </p>
                        <p id="landingpara" className='font-light lg:text-[20px] sm:text-[14px]'>Lincfolio is India’s first all-in-one trading journal tool, designed to help you <br/> track, analyze, and optimize your trades with ease.</p>
                        <p id="landingpara" className='font-light lg:text-[20px] sm:text-[14px]'>Whether you’re a seasoned trader or just starting out, Lincfolio provides <br/> the tools you need to make informed decisions and improve your trading <br/> performance.</p>
                        <a className="btn btn-dark" href="https://app.lincfolio.com">Get Started</a>
                    </div>
                    <div className='col-lg-6'>
                        <img src={Laptop} className='img-fluid' id='laptop'/>
                    </div>
                
                </div>
            </div>
        </div>
    </section>
  )
}

export default LandingPage