import React, { useEffect, useState } from 'react'
import Spinner from './Spinner';
import { Navigate, Outlet } from 'react-router-dom';



const Loader = () => {
  const handleLoading = () => {
    console.log('Page fully loaded');
    setIsLoading(false);
  };
  
  
  useEffect(()=>{
    window.addEventListener("load",handleLoading);
    return () => window.removeEventListener("load",handleLoading);
  },[])

  const [isLoading, setIsLoading] = useState(true);
  // return !isLoading ? (<Outlet/>):(<Spinner/>)
  return <Outlet/>
  // return <Spinner/>
}

export default Loader