import { useGLTF, useTexture } from '@react-three/drei';
import React, { useEffect, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const MacContainer = (props) => {

    const macref = useRef();
    // useGLTF();
    let model = useGLTF("./mac.glb");
    let tex = useTexture("./red.jpg");
    let meshes = {};
    model.scene.traverse((e)=> {
        meshes[e.name] = e;
    });
    meshes.matte.material.map = tex;
    meshes.matte.material.emissiveIntensity = 0;
    meshes.matte.material.metalness = 0;
    meshes.matte.material.roughness = 0;
    gsap.registerPlugin(ScrollTrigger);
    
    useGSAP(()=>{
        let tl = gsap.timeline();
        ScrollTrigger.create({
            animation: tl,
            trigger: "#brandstory",
            start: "top -20%",
            scrub: 4,
        });

        tl.to(macref.current.position,
        {
            x: -10,
            duration: 1    
        });
        tl.to(macref.current.rotation,{
            y: 1
        }, "-=1")       
    },[]);

    useEffect(()=> {

        console.log(props.tmposition);
    })
    // [25, -10, 20]
    return (
        <group position={[25, -10, 20]} ref={macref}>
            <primitive object={model.scene}/>
        </group>

  )
}

export default MacContainer