import React from 'react'
const Terms = () => {

  return (
    <div>
        <h1>
            *Terms and Conditions*
        </h1>

        <h4>
            *1. Acceptance of Terms*  
        </h4>
        <p>
            By accessing and using Lincfolio, you agree to abide by these terms. If you do not agree, please discontinue use of the platform.
        </p>

        <h4>
            *2. Eligibility*  
        </h4>
        <p>
            Users must be at least 13 years old. By registering, you confirm that all information provided is accurate.
        </p>

        <h4>
            *3. User Responsibilities*
        </h4>
        <p>
            You are responsible for maintaining the confidentiality of your account and for any activities under your account.
        </p>

        <h4>
            *4. Intellectual Property*  
        </h4>
        <p>
            All content on Lincfolio, including p, graphics, and logos, is owned by Lincfolio and protected by intellectual property laws.
        </p>

        <h4>
            *5. Content and Data Usage*  
        </h4>
        <p>
            By submitting content, you grant Lincfolio a non-exclusive license to use, display, and distribute your content as necessary for platform functionality.
        </p>
    
        <h4>
            *6. Limitations of Liability*  
        </h4>
        <p>
            Lincfolio is not responsible for any trading losses or decisions made based on platform insights. Use the platform at your own risk.
        </p>  

        <h4>
            *7. Prohibited Activities*
        </h4>
        <p>
            Prohibited actions include unauthorized access, scraping, distributing harmful content, and violating others' intellectual property rights.
        </p>  
    
        <h4>
            *8. Termination* 
        </h4>
        <p>
            Lincfolio reserves the right to suspend or terminate accounts that violate these terms without prior notice.
        </p>    

        <h4>
            *9. Governing Law*  
        </h4>
        <p>
            These terms shall be governed by the laws of Maharashtra, India.
        </p> 
 
        <h4>
            *10. Changes to Terms*  
        </h4>
        <p>
            Lincfolio may update these Terms periodically. Users will be notified of any changes, and continued use signifies acceptance.   
        </p> 
    </div>
  )
}

export default Terms