import gsap from "gsap";
import {useGSAP} from "@gsap/react"
import React, {  useRef } from 'react';
import "../css/loader.css"

const Spinner = () => {
  const spansRef = useRef([]);

  gsap.registerPlugin(useGSAP);
  useGSAP(() => {
    var tl1 = gsap.timeline()
    var tl2 = gsap.timeline()
    // GSAP fromTo animation, explicitly defining start and end values
    tl1.to(".text__first-bg", {scaleX:1, duration: 0.2})
    tl1.to(".text__second-bg", {scaleX:1, duration: 0.2})
    tl1.to(".text__word", {opacity:1, duration: 0.1}, "-=0.1")  
    tl1.to(".text__first-bg", {scaleX:0, duration: 0.2})
    tl1.to(".text__second-bg" , {scaleX:0, duration: 0.2});
    tl1.to(".text__first-bg", {scaleX:1, duration: 0.2, delay: 2})
    tl1.to(".text__second-bg", {scaleX:1, duration: 0.2})
    tl1.to(".text__word", {opacity:0, duration: 0.1}, "-=0.1")
    tl1.to(".text__first-bg", {scaleX:0, duration: 0.2})
    tl1.to(".text__second-bg", {scaleX:0, duration: 0.2})
    tl1.to(".text", {display: "none", duration: 0.1})


    tl2.fromTo(".text2", 
        { y: 20, opacity: 0, display: "block", delay: 1 }, // Starting state: 100px down and invisible
        { y: 0, opacity: 1, duration: 0.5, ease: "power2.out" } // End state: natural position and visible
      ,"+=4");
    
    var tl3 = gsap.timeline({repeat: -1})
    tl3.to(".ball", {y: -5, duration: 0.5, stagger: 0.3, scrub: 3});
    tl3.to(".ball", {y: 0, duration: 0.5, stagger: 0.3, ease: "elastic.out(1,0.3)", scrub: 3});
  }, []);

  return (
<>spinner</>
  );
}

export default Spinner;
