import React from 'react'
const Cancellation = () => {

  return (
    <div>
        <h1>
        Cancellation and Refund Policy
        </h1>     
        
        <h4>
            Overview
        </h4>
        <p>
            At Lincfolio, we are committed to providing a premium, high-quality experience. To maintain the exclusive nature of our platform, we have established a strict cancellation and refund policy. Please review this policy carefully before making a purchase.
        </p>

        <h4>
            No Cancellations
        </h4>
        <p>
            All Lincfolio subscriptions are final and non-cancelable. Once you subscribe, you are committed to the full duration of the subscription period. We encourage you to consider your options carefully before committing to a subscription.
        </p>
        
        <h4>
            No Refunds
        </h4>
        <p>
            We do not offer refunds, either partial or full, for any Lincfolio subscription under any circumstances. This policy applies to all users and includes but is not limited to cases of non-usage, account inactivity, or early termination of use.
        </p>

        <h4>
            Account Management
        </h4>
        <p>
            You retain access to your Lincfolio account for the entirety of your subscription period. After your subscription expires, you may choose to renew or discontinue access without further obligation.
        </p>

        <h4>
            5. Contact Us
        </h4>
        <p>
            If you have questions or concerns regarding our policies, please reach out to our support team at support@lincfolio.com or business@lincfolio.com
        </p>       
        <p>
            Thank you for your understanding and support.
        </p>
    </div>
  )
}

export default Cancellation